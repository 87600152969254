.option {
	display: flex;
	align-items: center;
	width: 100%;

	.icon {
		width: 20px;
		height: 20px;
		margin-bottom: 0;

		.fix {
			margin-top: -15px; } }

	.label {
		margin-left: 10px; } }
